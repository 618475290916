{
  "2024-12-13": {
    "text": "Rendez-vous",
    "place": "Marché de Noël Grasse",
    "link": "https://www.paysdegrassetourisme.fr/fr/marche-noel-grasse"
  },
  "2024-12-14": {
    "text": "Rendez-vous",
    "place": "Noël éco-solidaire Nice",
    "link": "https://www.nice.fr/fr/actualites/noel-a-nice?type=articles"
  },
  "2024-12-15": {
    "text": "Rendez-vous",
    "place": "Marché de Noël Cabris et Grasse",
    "link": ""
  }
}